import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ShellComponent } from './shell/shell.component';
import { SeFeTabGroupModule, SeFeTabsModule } from 'se-fe-tabs';
import { SeFeHeaderModule } from 'se-fe-header';
import { SeFePageModule } from 'se-fe-page';
import { SeFeAuthSupportModule } from 'se-fe-auth-support';
import { environment } from 'src/environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { OAuthCallbackComponent } from './components/oauth-callback.component';
import { SpinnerComponent } from './components/spinner.component';
import { SeFeSpinnerModule } from 'se-fe-spinner';

@NgModule({
  declarations: [
    AppComponent,
    ShellComponent,
    OAuthCallbackComponent,
    SpinnerComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    SeFeAuthSupportModule.forRoot(environment),
    SeFeHeaderModule,
    SeFePageModule,
    SeFeSpinnerModule,
    SeFeTabGroupModule,
    SeFeTabsModule
  ],
  exports: [
    SpinnerComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
