import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthState } from 'se-fe-auth-support';

@Component({
  selector: 'app-oauth-callback',
  template: '<app-spinner></app-spinner>',
  styles: []
})
export class OAuthCallbackComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute) { }

  public ngOnInit(): void {
    const state = OAuthState.load()
    const queryParams = this.route.snapshot.queryParamMap

    if (!state.returnTo || state.state !== queryParams.get('state')) {
      this.router.navigate(['not-found'])
      return
    }

    this.router.navigateByUrl(state.returnTo)

  }
}