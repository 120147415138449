import { Environment } from "se-fe-auth-support";

export const environment: Environment = {
  domainSpecificUrls: [
    {
      domain: 'insights.ui.sportngin.com.dev',
      default: true,
      api: 'https://api.stage.ngin-staging.com/',
      user: 'https://user.stage.ngin-staging.com/'
    }
  ],
  env: 'staging',
  frameRouterOrigin: [
    'https://app.stage.ngin-staging.com'
  ],
  clientId: '0836c1a450b623ce3a6ae33f742d5274',
  defaultUrl: 'https://insights.ui.sportngin.com.dev',
  name: 'staging',
  production: false,
  staging: true,
  urls: {
    api: 'https://se-api.sestage.us',
    user: 'https://user.sestage.us/',
  }
}
