import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SeAuthGuardService } from 'se-fe-auth-support';
import { ShellComponent } from './shell/shell.component';
import { OAuthCallbackComponent } from './components/oauth-callback.component';

const routes: Routes = [
  { path: '', redirectTo: 'reports', pathMatch: 'full' },
  {
    path: 'reports',
    canActivate: [SeAuthGuardService],
    component: ShellComponent,
    data: {
      frameRouterTitle: 'Enterprise Reporting'
    }
  },
  {
    path: 'oauth/callback',
    component: OAuthCallbackComponent
  },
  { path: '**', redirectTo: 'error/not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
