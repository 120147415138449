import { Component } from '@angular/core'

@Component({
  selector: 'app-spinner',
  template: `<div class="se-app__section">
    <div pl-grid="align-center">
      <div pl-grid-el pl-grid="shrink@phone">
        <se-fe-spinner [seFeDataSize]="900"></se-fe-spinner>
      </div>
    </div>
  </div>`
})
export class SpinnerComponent {
  //
}
